import React from "react";
import { ServicesOverview } from "./ServicesData";
import PageThumbnail from "../../PageComponents/PageThumbnail";
import { grobsSynthFeaturedImage, grobsSynthShortDescription, grobsSynthTitle } from "../../Projects/FullProjects/GrobsSynth/GrobsSynthData";
import { addItemsWithResourcePickerImage, stockTakeManagementAppShortDescription, stockTakeManagementAppTitle } from "../../Projects/FullProjects/StockTakeManagementApp/StockTakeManagementAppData";
import { yellow, pink, purple, green, lightGreen, lightPurple, lightYellow, lightPink } from "../../colors";
import { grobsJobsFeaturedImage, grobsJobsShortDescription, grobsJobsTitle } from "../../Projects/FullProjects/GrobsJobs/GrobsJobsData";
import { customerJourneyShortDescription, customerJourneyTitle, customerJourneyPrototypeImageExport } from "../../Projects/FullProjects//CustomerJourney/CustomerJourneyData";
import { measuredShortDescription, measuredTitle, measuredPrototypeImageExport } from "../../Projects/FullProjects//Measured/MeasuredData";

export default function Home(){
    return(
        <div className="content-centralised-container">
            <div className='page-top-spacer'></div>
            <ServicesOverview />
            <div className='page-top-spacer'></div>
            <div className="project-thumbnail-space-around-container">
                <PageThumbnail to={'/GrobsSynth'} title={grobsSynthTitle} description={grobsSynthShortDescription} color={purple} borderColor={lightPurple} imageFileName={grobsSynthFeaturedImage} imageAlt={"Grob's Synth"}/>
                <PageThumbnail to={'/StockTakeManagementApp'} title={stockTakeManagementAppTitle} description={stockTakeManagementAppShortDescription} color={green} borderColor={lightGreen} imageFileName={addItemsWithResourcePickerImage} imageAlt={"Resource Picker Image"}/>
                <PageThumbnail to={'/GrobsJobs'} title={grobsJobsTitle} description={grobsJobsShortDescription} color={pink} borderColor={lightPink} imageFileName={grobsJobsFeaturedImage} imageAlt={"Grob's Jobs homepage"}/>
                {/* <PageThumbnail to={'/CustomerJourney'} title={customerJourneyTitle} description={customerJourneyShortDescription} color={yellow} borderColor={lightYellow} imageFileName={customerJourneyPrototypeImageExport} imageAlt={"Customer Journey Prototype"}/> */}
                 <PageThumbnail to={'/Measured'} title={measuredTitle} description={measuredShortDescription} color={yellow} borderColor={lightYellow} imageFileName={measuredPrototypeImageExport} imageAlt={"Customer Journey Prototype"}/>
            </div>
            <div className='page-bottom-spacer'></div>
        </div>
    )
}