import measuredPrototypeImageImport from '../../../images/measured/06406256-CCF0-4E35-AFCE-CA3C7654B24D_1_201_a.jpeg'

export const measuredPrototypeImageExport = measuredPrototypeImageImport;

export const measuredTitle = `Measured`

export const measuredShortDescription = `A web application that makes finding the right clothing size more accurate.`;

export const measuredFullDescription = `This is an app that is currently in development. It focuses on minimising returns for
both brands and consumers alike, cutting out unecessary faff and giving the customer confidence that they're buying something that
fits them properly. This is the first of its kind, as it (with some willing participation from the user), goes beyond what other
similar applications do today.`