import { useEffect } from "react";
import ProjectPageTitle from "../../../PageComponents/ProjectPageTitle";
import { 
    measuredFullDescription,
    measuredTitle,
    measuredPrototypeImageExport
} from "./MeasuredData";
import ImageBlock from "../../../PageComponents/ImageBlock";

export default function MeasuredProjectPage(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <div className="content-centralised-container" style={{marginBottom: "50px"}}>
            <div className='page-top-spacer'></div>
            <ProjectPageTitle title={measuredTitle}/>
            <p className="general-block-of-text" style={{marginTop: "20px"}}>{measuredFullDescription}</p>
            <ImageBlock imageFileName={measuredPrototypeImageExport} optionalWidth={'50%'} />
        </div>
    )
}